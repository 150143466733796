import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import leftArrow from '../../../../assets/images/left-arrow.png'
import rightArrow from '../../../../assets/images/right-arrow.png'
import Button from '../../button/Button'
import './channelCarousel.scss'
import EditCarousel from '../EditCarousel/EditCarousel'

const ChannelCarousel = ({ item }) => {
  const [active, setActive] = useState(0)
  const [isDesktop, setIsDesktop] = useState(true)
  const [itemIsSelected, setItemIsSelected] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  //-----
  const previewRef = React.useRef()
  const previewContainerRef = React.useRef()
  //------
  let position = 0
  let trackpadEnabled = true
  const quantToShow = 3

  useEffect(() => {
    const scrollExtraValue = isDesktop.isDesktop ? 1 : 2.5
    const scrollExtra = active === item.assets.length - quantToShow ? scrollExtraValue : 0
    previewRef.current.scrollLeft = Math.ceil((!isDesktop ? (window.screen.width * 0.30)
      : 0.33 * previewRef.current.offsetWidth)
    * active) - scrollExtra
  }, [active])

  const goNext = () => {
    // Move to item to the right
    setActive(
      (oldActive) => ((oldActive < item.assets.length - quantToShow)
        ? (oldActive + quantToShow) % Math.ceil(item.assets.length + quantToShow) : 0),
    )
  }

  const goBack = () => {
    // Move to item to the left
    setActive((oldActive) => (oldActive !== 0
      ? oldActive - quantToShow
      : Math.ceil(item.assets.length) - quantToShow))
  }

  const goTo = (index) => {
    // Move to selected item
    setActive(index)
  }

  /**
   * Managing movement with touch pad
   */
  const handleTouchEnd = ({ changedTouches }) => {
    const horizontalMovement = (changedTouches[0]?.clientX || 0) - position
    if (horizontalMovement > 50) {
      goBack()
    } else if (horizontalMovement < -50) {
      goNext()
    }
    window.removeEventListener('touchend', handleTouchEnd)
  }

  /**
   * Managing movement with touch pad
   */
  const handleTouchStart = ({ changedTouches, target }) => {
    if (target.classList[0] !== 'preview-item') return
    position = changedTouches[0]?.clientX
    window.addEventListener('touchend', handleTouchEnd)
  }

  /**
   * Managing movement with touch pad
   */
  const handleTrackpadStart = ({ target, deltaX }) => {
    if (!trackpadEnabled || Math.abs(deltaX) < 30 || target.classList[0] !== 'preview-item') return
    if (deltaX > 30) {
      trackpadEnabled = false
      setTimeout(() => {
        trackpadEnabled = true
      }, 500)
      goNext()
    } else if (deltaX < -30) {
      trackpadEnabled = false
      setTimeout(() => {
        trackpadEnabled = true
      }, 500)
      goBack()
    }
  }

  /**
   * Handle if it is a desktop or phone
   * Add necessary event listeners
   */
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth >= 1024) {
        setIsDesktop(true)
      } else setIsDesktop(false)
    }

    handleResize() // Change screen on first render
    window.addEventListener('resize', handleResize) // Change screen variable on screen change
    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('mousewheel', handleTrackpadStart)
    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('mousewheel', handleTrackpadStart)
    }
  }, [])

  const setSelectedItem = (index) => {
    setItemIsSelected(!itemIsSelected)
    setSelectedIndex(index)
  }

  return (
    <>
      <div className="preview" ref={previewContainerRef}>
        <Button onClick={goBack} classButton="left-arrow" icon={[leftArrow]} iconAlt="left-arrow" />
        <div className="carousel-wrapper">
          <div className="preview-list" ref={previewRef}>
            {
                item.assets.map((asset, index) => (
                  <button
                    key={asset.id}
                    alt="preview-item"
                    className="preview-item"
                    onClick={() => setSelectedItem(index)}
                    type="button"
                    style={{
                      left: `${index * (isDesktop ? '20vw' : '30vw')}`,
                      backgroundImage: `url(${asset.imageSrcLogo['1x1']})`,
                    }}
                  >
                    <div className="pen" />
                  </button>
                ))
                }
          </div>
          <div className="dot-list">
            {
                [...Array(Math.ceil(item.assets.length / quantToShow))]?.map((x, index) => (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <button key={`dot-${index.toString()}`} onClick={() => goTo(index)} type="button" className={`${index === ((active === 0) ? active : active / 3) ? 'dot-active' : 'dot'}`} />))
          }
          </div>
        </div>
        <Button onClick={goNext} classButton="right-arrow right-arrow-align" icon={[rightArrow]} iconAlt="right-arrow" />
      </div>
      { itemIsSelected
        ? (
          <EditCarousel
            itemInfo={item}
            position={selectedIndex}
            imageType="imageSrcLogo"
            iar="1x1"
            onClick={() => setItemIsSelected(!itemIsSelected)}
          />
        )
        : ''}
    </>
  )
}

ChannelCarousel.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    assets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        imageSrcHero: PropTypes.shape({
          type: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
}

export default ChannelCarousel
