var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var programAdaptor = function (epgProgram) {
    var _a, _b;
    var program = {
        id: epgProgram._id,
        name: epgProgram.name,
        description: epgProgram.description,
        start: new Date(epgProgram.start),
        end: new Date(epgProgram.stop),
        categories: epgProgram.categories,
        inCarousel: epgProgram.inCarousel,
        imageSrcThumbnail: Object.assign.apply(Object, __spreadArray([{}, {}], (_a = epgProgram === null || epgProgram === void 0 ? void 0 : epgProgram.images) === null || _a === void 0 ? void 0 : _a.filter(function (image) { return image.type === 'thumbnail'; }), false)) || {},
        imageSrcHero: Object.assign.apply(Object, __spreadArray([{}, {}], (_b = epgProgram === null || epgProgram === void 0 ? void 0 : epgProgram.images) === null || _b === void 0 ? void 0 : _b.filter(function (image) { return image.type === 'hero'; }), false)) || {},
    };
    return program;
};
export var channelAdaptor = function (epgChannel) {
    var _a;
    var channel = {
        id: epgChannel._id,
        programs: epgChannel.programs.map(function (program) { return programAdaptor(program); }),
        inCarousel: epgChannel.inCarousel,
        imageSrcLogo: Object.assign.apply(Object, __spreadArray([{}, {}], (_a = epgChannel === null || epgChannel === void 0 ? void 0 : epgChannel.images) === null || _a === void 0 ? void 0 : _a.filter(function (image) { return image.type === 'logo'; }), false)) || {},
        description: epgChannel.description,
        name: epgChannel.name,
        url: epgChannel.url
    };
    return channel;
};
