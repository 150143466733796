import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Hero from './hero/Hero'
import ChannelCarousel from './channelsCarousel/ChannelCarousel'
import './carousels.scss'

import ExpandingCarousel from './ExpandingCarousel/ExpandingCarousel'

import { editedCarousels, loggedUser, SET_EDITED_CAROUSELS } from '../../UserContext/UserReducer'
import { getCarousels, publishCarousels } from '../../services/carousels/carousel.service.tsx'
import { fetchEPGRegions } from '../../services/epg.service.tsx'
import Selector from '../selector/Selector'

import region from '../../../assets/images/Region.svg'
import Button from '../button/Button'
import { displayFilteredData } from '../../utils/EventUtils'

const CarouselsScreen = ({ handleSpinner, handleError }) => {
  const userState = useSelector(loggedUser)
  const carouselsState = useSelector(editedCarousels)
  const dispatch = useDispatch()

  const [regionsAvailable, setRegionsAvailable] = useState([])
  const [selectedRegion, setSelectedRegion] = useState()
  const [carousels, setCarousels] = useState([])

  // FETCH FOR AVAILABLE REGIONS
  useEffect(() => {
    handleSpinner(true)
    fetchEPGRegions(userState.authToken).then((res) => {
      const [statusCode, data] = res
      if (statusCode === 200) {
        handleError(false)
        setRegionsAvailable([...data])
        setSelectedRegion(data[0])
      } else {
        handleError(true)
      }
      handleSpinner(false)
    })
  }, [])

  useEffect(() => {
    if (!selectedRegion) return
    handleSpinner(true)

    getCarousels(selectedRegion.id, userState.authToken).then((res) => {
      const [statusCode, data] = res
      // eslint-disable-next-line no-console
      console.log('data', data)
      if (statusCode === 200) {
        setCarousels(data)
        handleError(false)
      } else {
        handleError(true)
      }
      handleSpinner(false)
    })
  }, [selectedRegion])

  // HANDLE REGION DROPDOWN CHANGE
  const handleRegionChange = (e) => {
    setSelectedRegion(regionsAvailable[e.target.value]) // Region object
  }

  /**
   * Publish new json for frontend
   */
  const publishChanges = () => {
    handleSpinner(true)
    publishCarousels(selectedRegion.id, userState.authToken).then((statusCode) => {
      if (statusCode !== 200) handleError(true)
      else {
        dispatch(SET_EDITED_CAROUSELS({
          editedCarousels: false,
        }))
      }
      handleSpinner(false)
    })
  }

  return (
    <div className="carousels-screen">
      <div className="screen-header">
        <div className="upper-header">
          <h2>Carousels Preview</h2>
          {
              selectedRegion
                ? displayFilteredData(selectedRegion)
                : ''
          }
        </div>
        <div className="lower-header">
          <Selector iconAlt="region" icon={region} handleChange={handleRegionChange} items={regionsAvailable} />
          <Button
            classButton={`publish ${carouselsState ? 'changes_detected' : ''}`}
            onClick={carouselsState ? publishChanges : () => {}}
            text="Publish"
          />
        </div>
      </div>
      <div className="carousels-scrollable">
        {
          carousels.map((item) => (
            <div className="carousel" key={item.id}>
              <h3>{item.name}</h3>
              { item.type === 'hero' ? <Hero carousel={item} /> : '' }
              { item.type === 'normal' ? <ChannelCarousel item={item} /> : '' }
            </div>
          ))
        }
        <h3>Expandable Carousel</h3>
        <ExpandingCarousel data={carousels[0]} />
      </div>
    </div>
  )
}

CarouselsScreen.propTypes = {
  handleSpinner: PropTypes.func,
  handleError: PropTypes.func,
}

CarouselsScreen.defaultProps = {
  handleSpinner: undefined,
  handleError: undefined,
}

export default CarouselsScreen
