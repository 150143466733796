/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import back from '../../../assets/images/Back.svg'
import logo from '../../../assets/images/logoWot.png'
import './passwordHeader.scss'

const PasswordFlowHeader = ({ route }) => {
  const navigate = useNavigate()
  return (
    <div className="password-header">
      <img src={back} role="button" onClick={() => navigate(`/${route}`)} alt="Go back" className="back" />
      <img src={logo} alt="WotLogo" className="logo-header" />
    </div>
  )
}
PasswordFlowHeader.propTypes = {
  route: PropTypes.string,
}
PasswordFlowHeader.defaultProps = {
  route: undefined,
}

export default PasswordFlowHeader
