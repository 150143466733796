import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Channel from './Channel'
import { returnScheduleContext } from '../ScheduleContext'
import './channel.scss'

const Channels = ({ setChannelRef, handleSpinner }) => {
  const channelsList = React.useRef()
  const {
    scheduleJson, channelHeight, hoursHeight,
    paddingSize, quantToShow, setScheduleJson, getEmptySpaceHeight,
  } = returnScheduleContext()

  /**
   * Scroll channels
   */
  useEffect(() => {
    if (channelsList.current) {
      setChannelRef(channelsList.current)
    }
  }, [channelsList])

  useEffect(() => {
    handleSpinner(!scheduleJson)
  }, [scheduleJson])

  useEffect(() => {
    setTimeout(() => {
      getEmptySpaceHeight()
    }, 500)
  }, [channelHeight])

  // handle image aspect ratio
  useEffect(() => {
    const handleResize = () => {
      handleSpinner(true)
      setTimeout(() => {
        getEmptySpaceHeight()
      }, 500)
    }

    handleResize() // Chech resize in first render
    window.addEventListener('resize', handleResize) // Change resize on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (scheduleJson) handleSpinner(false)
  }, [quantToShow])

  return (
    <div className="left-column">
      <div className="top-container" style={{ height: hoursHeight, width: channelHeight }} />
      <div className="channels-list" style={{ maxHeight: (channelHeight * quantToShow) }} ref={channelsList}>
        {
        scheduleJson?.map((channel) => (
          <Channel
            key={channel.id}
            channelInfo={{ ...channel, assetType: 'channel' }}
            channelHeight={channelHeight}
            paddingSize={paddingSize}
            scheduleJson={scheduleJson}
            setScheduleJson={setScheduleJson}
          />
        ))
      }
      </div>
    </div>
  )
}

Channels.propTypes = {
  setChannelRef: PropTypes.func.isRequired,
  handleSpinner: PropTypes.func.isRequired,
}

export default Channels
