/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './clock.scss'

const Clock = (props) => {
  const { item, dt } = props
  const [timerEnd, setTimerEnd] = useState(false)

  const [days, setDays] = useState('00')
  const [hours, setHours] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const [seconds, setSeconds] = useState('00')

  const second = 1000
  const minute = second * 60
  const hour = minute * 60
  const day = hour * 24

  /**
   * Calculate time before event starts
   * @param {date} end Date end of event
   */
  const calculateTimer = (end) => {
    const now = new Date()
    const distance = end - now

    if (distance > 0) {
      setDays(Math.floor(distance / day).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }))
      setHours(Math.floor((distance % day) / hour).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }))
      setMinutes(Math.floor((distance % hour) / minute).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }))
      setSeconds(Math.floor((distance % minute) / second).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }))
    } else {
      setTimerEnd(true)
    }
  }

  useEffect(() => {
    const end = new Date(dt)
    let timer

    // If it is an special event and there is a date
    if (item.highlight && !timer && dt && !timerEnd) {
      // Set timer at first render
      calculateTimer(end)

      timer = setInterval(() => {
        // Update timer every second
        calculateTimer(end)
      }, 1000)
    }
    return () => {
      clearInterval(timer)
    }
  }, [dt])

  if (item.highlight && !timerEnd) {
    return (
      <div className="countdown-clock">
        {
            days > 0
              ? (
                <div className="countdown-element">
                  <span className="countdown-number">{days}</span>
                  <span className="countdown-label">Days</span>
                </div>
              )
              : null
          }
        {days > 0 ? <div className="countdown-number">:</div> : null}
        <div className="countdown-element">
          <span className="countdown-number">{hours}</span>
          <span className="countdown-label">Hours</span>
        </div>
        <div className="countdown-number">:</div>
        <div className="countdown-element">
          <span className="countdown-number">{minutes}</span>
          <span className="countdown-label">Minutes</span>
        </div>
        <div className="countdown-number">:</div>
        <div className="countdown-element">
          <span className="countdown-number">{seconds === 0 ? '00' : seconds}</span>
          <span className="countdown-label">Seconds</span>
        </div>
      </div>
    )
  }
  return (dt
      && (
      <div className="hero-clock">
        <span className="clock-date">{dt}</span>
      </div>
      )
  )
}

Clock.propTypes = {
  item: PropTypes.shape({
    highlight: PropTypes.bool.isRequired,
  }).isRequired,
  dt: PropTypes.string,
}

Clock.defaultProps = {
  dt: '',
}

export default Clock
