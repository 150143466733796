// Function to get current time based on timezone offset
export const getCurrentDateTime = () => {
  const tzoffset = (new Date()).getTimezoneOffset() * 60000 // offset in milliseconds
  const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, 19).replace('T', ' ')
  return localISOTime
}

// Function to check if date d1 is the same as date d2 (year/month/day)
export const checkSameDate = (d1, d2) => {
  let result = false

  try {
    result = d1.getFullYear() === d2.getFullYear()
    && d1.getMonth() === d2.getMonth()
    && d1.getDate() === d2.getDate()
  } catch (e) {
    result = false
  }

  return result
}

// Get current minute based on current date
export const getCurrentMinute = () => {
  // get complete date
  const currentDate = new Date()
  // get current date
  const actualDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`
  // rest the day from the actual time
  const currentTime = Math.abs(new Date(actualDate.replace(/-/g, '/')) - currentDate)
  // get the time of the user on minutes
  return Math.floor(currentTime / 60000)
}

// Function to check if programs ends at midnight
export const checkIfIsMidnight = (d1) => (d1.getHours() === 0 && d1.getMinutes() === 0)
