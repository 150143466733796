const NewPasswordChallenge = async (credentials) => {
  try {
    return await fetch(`${process.env.API_URL}/login/challenge`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        challenge: credentials.challenge,
        username: credentials.user,
        password: credentials.password,
        session: credentials.session,
      }),
    }).then(async (res) => [res.status, await res.json()])
  } catch (error) {
    return ('Something went wrong. Error: ', error)
  }
}

export default NewPasswordChallenge
