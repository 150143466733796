/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PasswordFlowHeader from '../Templates/PasswordFlowHeader'
import '../login/Login.scss'
import InputCodeBox from './InputCodeBox'

const CodeActivationScreen = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [email, setEmail] = useState()
  const [code, setCode] = useState([...Array(6)].map(() => ''))

  const handleCodeChange = (newCode) => setCode(newCode)
  useEffect(() => {
    const { state } = location
    const mail = state?.mail
    if (mail) {
      setEmail(mail)
    }
  }, [])

  return (
    <div className="screen-wrapper">
      <div className="form-wrapper">
        <PasswordFlowHeader route="forgotPassword" />
        <div className="form-body">
          <div className="box-code">
            <InputCodeBox handleCodeChange={handleCodeChange} code={code} />
          </div>
          <input type="button" className="form-btn" value="Verify" onClick={() => navigate('/newPassword', { state: { mail: email, code } })} />
        </div>
      </div>
    </div>
  )
}

export default CodeActivationScreen
