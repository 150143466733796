const refreshAuthToken = async (refresh) => {
  try {
    // REFRESH AUTH TOKEN. THIS FUNCTION SHOULD BE CALL EVERY HOUR IF USER IS ACTIVE
    return await fetch(`${process.env.API_URL}/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh,
      }),
    }).then(async (res) => [res.status, await res.json()])
  } catch (error) {
    return ('Something went wrong. Error: ', error)
  }
}

export default refreshAuthToken
