import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({
  label, value, checked, handleItemChange,
}) => (
  <label htmlFor={label} className="checkbox-container">
    <input
      defaultChecked={checked}
      className="checkbox-input"
      type="checkbox"
      id={label}
      value={value}
      onChange={handleItemChange}
    />
    <span className="checkbox-text" />
    <p>{label}</p>
  </label>
)

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  handleItemChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool,
}

Checkbox.defaultProps = {
  value: '',
  checked: false,
}

export default Checkbox
