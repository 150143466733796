var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { channelAdaptor } from './adaptors/Adaptors';
/**
 * Get selected date EPG
 * @param date -> date to fetch
 * @returns cleanned EPG
 */
export var fetchEPG = function (date, token, regionID, offset) { return __awaiter(void 0, void 0, void 0, function () {
    var midnight, _a, status_1, EPG, completeEPG, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                midnight = new Date(date.replace(/-/g, '/'));
                midnight.setHours(0, 0, 0, 0);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/schedule/partner?from=").concat(midnight.toISOString().split('T')[0]).concat("&regionID=".concat(regionID), "&offset=").concat(midnight.getTimezoneOffset()), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            secret: "".concat(process.env.SECRET)
                        }),
                    })
                        .then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = [res.status];
                                return [4 /*yield*/, res.json()];
                            case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                        }
                    }); }); })];
            case 1:
                _a = _b.sent(), status_1 = _a[0], EPG = _a[1];
                return [4 /*yield*/, EPG.map(function (channel) {
                        return channelAdaptor(channel);
                    })];
            case 2:
                completeEPG = _b.sent();
                console.log(completeEPG);
                return [2 /*return*/, [status_1, completeEPG]];
            case 3:
                error_1 = _b.sent();
                return [2 /*return*/, ("Something went wrong. Error: ".concat(error_1))];
            case 4: return [2 /*return*/];
        }
    });
}); };
/**
 * Publish EPG changes on selected date
 * @param date -> date to edit
 * @returns updated EPG
 */
export var publishEPG = function (date, regionID, token) { return __awaiter(void 0, void 0, void 0, function () {
    var midnight, res, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                midnight = new Date(date.replace(/-/g, '/'));
                midnight.setHours(0, 0, 0, 0);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/schedule"), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer ".concat(token)
                        },
                        body: JSON.stringify({
                            from: midnight.toISOString().split('T')[0],
                            regionID: regionID,
                            offset: midnight.getTimezoneOffset(),
                        })
                    })];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.status];
            case 2:
                error_2 = _a.sent();
                return [2 /*return*/, ("Something went wrong. Error: ".concat(error_2))];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * Get EPG categories
 * @returns categories object
 */
export var fetchEPGCategories = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var EPGcategories, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/categories"), {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer ".concat(token)
                        },
                    }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = [res.status];
                                return [4 /*yield*/, res.json()];
                            case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                        }
                    }); }); })];
            case 1:
                EPGcategories = _a.sent();
                return [2 /*return*/, EPGcategories];
            case 2:
                error_3 = _a.sent();
                return [2 /*return*/, ("Something went wrong. Error: ".concat(error_3))];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * EDIT EPG program
 * @returns status code
 */
export var editEPGProgram = function (editProgramModel, token) { return __awaiter(void 0, void 0, void 0, function () {
    var res, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/programs"), {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer ".concat(token)
                        },
                        body: JSON.stringify(editProgramModel)
                    })];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.status];
            case 2:
                error_4 = _a.sent();
                return [2 /*return*/, ("Something went wrong. Error: ".concat(error_4))];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * EDIT EPG channel
 * @returns status code
 */
export var editEPGChannel = function (editChannelModel, token) { return __awaiter(void 0, void 0, void 0, function () {
    var res, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/channel"), {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer ".concat(token)
                        },
                        body: JSON.stringify(editChannelModel)
                    })];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.status];
            case 2:
                error_5 = _a.sent();
                return [2 /*return*/, ("Something went wrong. Error: ".concat(error_5))];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * EDIT EPG categories
 * @returns status code
 */
export var editEPGCategories = function (editCategoriesModel, token) { return __awaiter(void 0, void 0, void 0, function () {
    var res, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/categories"), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer ".concat(token)
                        },
                        body: JSON.stringify(editCategoriesModel)
                    })];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.status];
            case 2:
                error_6 = _a.sent();
                return [2 /*return*/, ("Something went wrong. Error: ".concat(error_6))];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * Get EPG available regions
 * @returns regions object
 */
export var fetchEPGRegions = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/regions"), {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer ".concat(token)
                        },
                    }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = [res.status];
                                return [4 /*yield*/, res.json()];
                            case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                        }
                    }); }); })];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                error_7 = _a.sent();
                return [2 /*return*/, ("Something went wrong. Error: ".concat(error_7))];
            case 3: return [2 /*return*/];
        }
    });
}); };
