/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SET_USER_LOGGED_OUT, loggedUser } from '../../UserContext/UserReducer'
import Button from '../button/Button'
import logout from '../../../assets/images/Logout.svg'
import './dropdowns.scss'
import tabsJson from '../../../assets/json/tabs.json'

const HamburguerMenu = ({ handleClose }) => {
  // const navigate = useNavigate()
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    setTabs(tabsJson)
  }, [])
  const [open, setIsOpen] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userState = useSelector(loggedUser)
  const dropdownRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    if (open && !isSmallScreen) {
      document.addEventListener('click', handleClickOutside, true)
    }
    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [open, isSmallScreen])
  // Checking if it is a small screen
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth < 1080) {
        setIsSmallScreen(true)
        setIsOpen(true)
      } else {
        setIsSmallScreen(false)
        setIsOpen(false)
      }
    }
    handleResize() // Change screen size on first render
    window.addEventListener('resize', handleResize) // Change ia on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const logoutHandler = () => {
    dispatch(SET_USER_LOGGED_OUT(userState))
    handleClose(false)
    navigate('/')
  }
  useEffect(() => {
    if (isSmallScreen && !open) {
      setIsOpen(true)
    }
  }, [])
  return (
    <ul ref={dropdownRef} className={`navbarButton ${isSmallScreen ? 'tabs-section' : 'dropdown'} ${open ? 'open-dropdown' : ''}`}>
      {
            open
              ? (
                <li className="dropdown-elements">
                  {
                        tabs.map((section) => (
                          section.tabs?.length > 0 ? (
                            section.tabs?.map((tab) => (
                              <Button
                                iconAlt={tab.tabName}
                                icon={[tab.icon]}
                                text={tab.tabName}
                                classButton="responsive-side-bar"
                              />
                            ))
                          ) : ''
                        ))
                    }
                  <Button buttonType="logout" onClick={() => logoutHandler()} text="Sign Out" icon={[logout]} classButton="responsive-side-bar" />
                </li>
              ) : (
                ''
              )
        }
    </ul>
  )
}

export default HamburguerMenu
