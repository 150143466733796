/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  SET_USER_LOGGED,
  SET_USER_LOGGED_OUT,
  SET_USER_CHALLENGED,
  loggedUser,
} from '../../UserContext/UserReducer'
import './Login.scss'
import logo from '../../../assets/images/loginLogo.png'
import logInUser from '../../services/LogIn/LoginHandler'
import InputComponent from '../Templates/InputComponent'
import LoginFlowTitles from '../Templates/LoginFlowTitles'

const Login = ({ handleSpinner }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState('')
  const userState = useSelector(loggedUser)
  const [password, setPassword] = useState('')
  const [isLoginOk, setIsLoginOk] = useState(true)
  const dispatch = useDispatch()

  const handleUserChange = (value) => setUser(value)
  const handlePasswordChange = (value) => setPassword(value)

  const handleLogin = async () => {
    handleSpinner(true)
    const [statusCode, response] = await logInUser({ user, password })

    const isValid = statusCode === 200 && response?.AuthenticationResult
    if (isValid) {
      dispatch(SET_USER_LOGGED({ // WE ONLY DO THE UPDATE ONCE THE LOGIN IS DONE CORRECTLY
        name: user,
        authToken: response.AuthenticationResult.AccessToken,
        refreshToken: response.AuthenticationResult.RefreshToken,
        isActive: true,
      }))
      handleSpinner(false)
      navigate('/epg')
    } else if (response?.ChallengeName && response?.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
      dispatch(SET_USER_CHALLENGED({ // WE ONLY DO THE UPDATE ONCE THE LOGIN IS DONE CORRECTLY
        name: user,
        sessionToken: response.Session,
      }))
      handleSpinner(false)
      navigate('/newPassword')
    }
    handleSpinner(false)
    setIsLoginOk(isValid)
  }

  useEffect(() => {
    dispatch(SET_USER_LOGGED_OUT(userState))
  }, [])

  return (
    <div className="screen-wrapper">
      <div className="form-wrapper">
        <img src={logo} alt="Logo" className="logo" />
        <div className="form-body">
          <LoginFlowTitles classSelected="span-wrapper" title="Email" />
          <InputComponent valueToHandle={user} setValueToHandle={handleUserChange} isLoginOk={isLoginOk} />
          <LoginFlowTitles classSelected="span-wrapper" title="Password" />
          <InputComponent valueToHandle={password} setValueToHandle={handlePasswordChange} isPassword icon="/assets/images/Toggle.png" iconToggled="/assets/images/Untoggle.png" isLoginOk={isLoginOk} />
          <div className="span-container">
            {
              (!isLoginOk)
                ? <LoginFlowTitles classSelected="span-wrapper" title="The username or password is not correct" spanClass="forgot-control forgot-control-error" />
                : ''
            }
            <div className="span-wrapper">
              <span className="forgot-control password-reset" tabIndex={0} role="button" onClick={() => navigate('/forgotPassword')}>Forgot your password?</span>
            </div>
          </div>
          <input type="button" className="form-btn" value="Sign In" onClick={() => handleLogin()} />
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  handleSpinner: PropTypes.func,
}

Login.defaultProps = {
  handleSpinner: undefined,
}

export default Login
