import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loggedUser } from '../../UserContext/UserReducer'
import Schedule from './Schedule'
import { FetchScheduleInfo } from './ScheduleContext'

const ScheduleScreen = ({
  handleSpinner, handleError, navbarRef, isSmallScreen,
}) => {
  const userState = useSelector(loggedUser)
  const navigate = useNavigate()
  useEffect(() => {
    if (!userState.authToken && !userState.isActive) navigate('/')
  }, [userState])
  useEffect(() => {
    handleError(false)
  }, [])
  return (
    <div className="schedule-screen">
      <FetchScheduleInfo>
        <Schedule
          handleSpinner={handleSpinner}
          handleError={handleError}
          navbarRef={navbarRef}
          isSmallScreen={isSmallScreen}
        />
      </FetchScheduleInfo>
    </div>
  )
}

ScheduleScreen.propTypes = {
  handleSpinner: PropTypes.func,
  handleError: PropTypes.func,
  navbarRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  isSmallScreen: PropTypes.bool,
}

ScheduleScreen.defaultProps = {
  handleSpinner: undefined,
  handleError: undefined,
  navbarRef: undefined,
  isSmallScreen: false,
}

export default ScheduleScreen
