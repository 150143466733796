/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { editedEPG, loggedUser, SET_EDITED_EPG } from '../../UserContext/UserReducer'
import { returnScheduleContext } from './ScheduleContext'
import Channels from './Channels/Channels'
import Programs from './Program/Programs'
import './schedule.scss'
import Button from '../button/Button'
import { publishEPG, fetchEPGRegions } from '../../services/epg.service.tsx'
import region from '../../../assets/images/Region.svg'
import Selector from '../selector/Selector'
import { displayFilteredData } from '../../utils/EventUtils'
import { getCurrentDateTime } from '../../utils/dateUtils'

const Schedule = ({
  handleSpinner, handleError, navbarRef,
}) => {
  const userState = useSelector(loggedUser)
  const [channelsRef, setChannelRef] = useState()
  const [regionsAvailable, setRegionsAvailable] = useState([])
  const todaysDate = getCurrentDateTime()
  const [actualDate, setActualDate] = useState(todaysDate)
  const [selectedRegion, setSelectedRegion] = useState(undefined)
  const { fetchEPGDate } = returnScheduleContext()

  const dispatch = useDispatch()
  const epgState = useSelector(editedEPG)

  /**
   * Publish new json
   */
  const publishChanges = async () => {
    handleSpinner(true)
    publishEPG(actualDate, selectedRegion.id, userState.authToken).then((res) => {
      if (res === 200) {
        dispatch(SET_EDITED_EPG({
          editedEPG: false,
        }))
        handleError(false)
      } else {
        handleError(true)
      }
      handleSpinner(false)
    })
  }

  /**
   * Fetch EPG on selected date change
   */
  useEffect(() => {
    handleSpinner(true)
    if (selectedRegion) {
      fetchEPGDate(actualDate, selectedRegion.id, selectedRegion.timezoneOffset).then((statusCode) => {
        if (statusCode === 200) {
          handleError(false)
          handleSpinner(false)
        } else {
          handleError(true)
        }
      })
    }
  }, [actualDate])

  // FETCH FOR AVAILABLE REGIONS
  useEffect(() => {
    fetchEPGRegions(userState.authToken).then((res) => {
      const [statusCode, data] = res
      if (statusCode === 200) {
        handleError(false)
        setRegionsAvailable([...data])
        setSelectedRegion(data[0])
        window.regionID = data[0].id
      } else {
        handleError(true)
      }
    })
  }, [])

  // HANDLE REGION DROPDOWN CHANGE
  const handleRegionChange = (e) => {
    setSelectedRegion(regionsAvailable[e.target.value]) // Region object
  }

  useEffect(() => {
    handleSpinner(true)

    if (selectedRegion) {
      fetchEPGDate(actualDate, selectedRegion?.id, selectedRegion?.timezoneOffset).then((statusCode) => {
        if (statusCode === 200) {
          handleError(false)
          handleSpinner(false)
        } else {
          handleError(true)
        }
      })
    }
  }, [selectedRegion])

  return (
    <>
      <div className="screen-header">
        <div className="upper-header">
          {
              selectedRegion
                ? displayFilteredData(selectedRegion)
                : ''
          }
        </div>
        <div className="lower-header">
          <Selector iconAlt="region" icon={region} handleChange={handleRegionChange} items={regionsAvailable} />
          <input
            type="date"
            id="EPG-date"
            name="EPG-start"
            defaultValue={todaysDate}
            min={todaysDate}
            onChange={(e) => setActualDate(e.target.value)}
          />
          <Button
            classButton={`publish-epg ${epgState ? 'changes_detected' : ''}`}
            onClick={epgState ? publishChanges : () => {}}
            text="Publish"
          />
        </div>
      </div>
      <div className="schedule">
        <Channels
          handleSpinner={handleSpinner}
          setChannelRef={setChannelRef}
        />
        <Programs
          channelsRef={channelsRef}
          selectedDate={actualDate}
          navbarRef={navbarRef}
        />
      </div>
    </>
  )
}

Schedule.propTypes = {
  handleSpinner: PropTypes.func,
  handleError: PropTypes.func,
  navbarRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
}

Schedule.defaultProps = {
  handleSpinner: undefined,
  handleError: undefined,
  navbarRef: undefined,
}
export default Schedule
