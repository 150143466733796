/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './inputcomponent.scss'

/**
 * @param {bool} isPassword DEFINE IF THE COMPONENT IS A PASSWORD TYPE WITH ICON OR JUST TEXT
 * @param {string} inputClass OPTIONAL CLASS
 * @param {string} icon FOR PASSWORD TYPE COMPONENT
 * @param {string} iconToggled FOR PASSWORD TYPE COMPONENT
 * @param {string} valueToHandle ACTUAL STATE
 * @param {Function} setValueToHandle UPDATE ACTUAL STATE
 * @param {bool} isLoginOk DEFINES IF IT REQUIRED TO HIGHLIGHT THE COMPONENT BORDER
 * @param {bool} useDefaultStyles OVERRIDE DEFAULT STYLES
 * @returns JSX component
 */
const InputComponent = ({
  isPassword, inputClass, icon, iconToggled, valueToHandle,
  setValueToHandle, isLoginOk, useDefaultStyles,
}) => {
  const [isPasswordToggled, setIsPasswordToggled] = useState(false)
  const isLoginOkClass = isLoginOk ? 'input-icon' : 'input-icon form-control-error '
  return (
    <div className="component-wrapper">
      {isPassword
        ? (
          <div className="icon-wrapper">
            <input type={(isPasswordToggled) ? 'text' : 'password'} className={(useDefaultStyles) ? `form-control ${isLoginOkClass}` : `${isLoginOkClass} ${inputClass}`} value={valueToHandle} onChange={(e) => setValueToHandle(e.target.value)} />
            <button type="button" onClick={() => setIsPasswordToggled((current) => !current)}>
              <img src={(!isPasswordToggled) ? icon : iconToggled} alt="togglePassword" className="toggle-icon" />
            </button>
          </div>
        )
        : <input type="email" className={(useDefaultStyles) ? `form-control ${isLoginOkClass}` : `${isLoginOkClass} ${inputClass}`} value={valueToHandle} onChange={(e) => setValueToHandle(e.target.value)} />}
    </div>
  )
}

InputComponent.propTypes = {
  isPassword: PropTypes.bool,
  inputClass: PropTypes.string,
  icon: PropTypes.string,
  iconToggled: PropTypes.string,
  valueToHandle: PropTypes.string,
  setValueToHandle: PropTypes.func,
  isLoginOk: PropTypes.bool,
  useDefaultStyles: PropTypes.bool,
}
InputComponent.defaultProps = {
  isPassword: false,
  inputClass: undefined,
  icon: '',
  iconToggled: '',
  valueToHandle: '',
  setValueToHandle: undefined,
  isLoginOk: true,
  useDefaultStyles: true,
}

export default InputComponent
