/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Routes, Route, Navigate,
} from 'react-router-dom'
import ScheduleScreen from './components/schedule/ScheduleScreen'
import Header from './components/Header/Header'
import Login from './components/login/Login'
import Navbar from './components/Nav/Navbar'
import SplashScreen from './components/splashscreen/SplashScreen'
import './components/Nav/Navbar.scss'
import CategoriesScreen from './components/categories/CategoriesScreen'
import Spinner from './components/splashscreen/Spinner'
import ErrorLog from './components/Error/Error'
import ResetPassword from './components/ResetPassword/ResetPassword'
import NewPassword from './components/NewPassword/NewPassword'
import CodeActivationScreen from './components/VerificationScreen/VerificationScreen'
import { SET_REFRESHED_AUTH_TOKEN, loggedUser } from './UserContext/UserReducer'
import refreshAuthToken from './services/Refresh/RefreshAuth'
import CarouselsScreen from './components/carousels/CarouselsScreen'
import usePageTracking from './utils/usePageTracking'

const App = () => {
  const userState = useSelector(loggedUser)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showError, setShowError] = useState(false)
  const navbarRef = useRef()

  const handleError = (status) => setShowError(status)
  const handleSpinner = (status) => setShowSpinner(status)

  useEffect(() => {
    setTimeout(() => {
      setShowSplashScreen(false)
    }, 3000)
  }, [])

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false)
      }, 5000)
    }
  }, [showError])

  const handleRefreshToken = async () => {
    if (userState.isActive && userState.refreshToken) {
      const [statusCode, response] = await refreshAuthToken(userState.refreshToken)
      if (statusCode === 200) dispatch(SET_REFRESHED_AUTH_TOKEN({ authToken: response.AuthenticationResult.AccessToken }))
    }
  }

  /**
   * Refresh access token on first render
   */
  useEffect(() => {
    handleRefreshToken()
  }, [])

  /**
   * Refresh access token every hour (long polling)
   */
  useEffect(() => {
    const timer = setInterval(() => handleRefreshToken(), 1000 * 60 * 60) // EVERY HOUR
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <>
      { usePageTracking() }
      { showSplashScreen ? <SplashScreen /> : ''}
      { showSpinner ? <Spinner /> : ''}
      <Header />
      { !showSplashScreen
        ? (
          <div className="app-wrapper">
            <Navbar navbarRef={navbarRef} />
            <div className="side-content">
              {showError ? <ErrorLog errorMssg="Oops! Something went wrong. Try again" /> : '' }
              <Routes>
                <Route
                  path="/"
                  element={(
                    <Login handleSpinner={handleSpinner} />
              )}
                />
                <Route
                  path="/forgotPassword"
                  element={(
                    <ResetPassword />
              )}
                />
                <Route
                  path="/verification"
                  element={(
                    <CodeActivationScreen />
              )}
                />
                <Route
                  path="/newPassword"
                  element={(
                    <NewPassword />
              )}
                />
                <Route
                  path="/epg"
                  element={(
                    <ScheduleScreen
                      handleSpinner={handleSpinner}
                      handleError={handleError}
                      navbarRef={navbarRef}
                    />
              )}
                />
                <Route
                  path="/categories"
                  element={(
                    <CategoriesScreen handleSpinner={handleSpinner} handleError={handleError} />
              )}
                />
                <Route
                  path="/carousels"
                  element={(
                    <CarouselsScreen handleSpinner={handleSpinner} handleError={handleError} />
              )}
                />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </div>
        )
        : ''}
    </>
  )
}

export default App
