/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import EditCarousel from '../EditCarousel/EditCarousel'
import './expandable.scss'

const ExpandingCarousel = ({ data }) => {
  const previewRef = React.useRef()
  const previewContainerRef = React.useRef()

  const [active, setActive] = React.useState(0)
  const [itemIsSelected, setItemIsSelected] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [carouselContainer, setcarouselContainer] = useState([])
  const colors = ['red', 'blue', 'green', 'orange', 'purple']
  const [isPhone, setIsPhone] = React.useState(false)
  const navigate = useNavigate()

  const setSelectedItem = (index) => {
    setItemIsSelected(!itemIsSelected)
    setSelectedIndex(index)
  }
  useEffect(() => {
    previewRef.current.scrollLeft = Math.ceil((isPhone
      ? 347 : 0.32 * previewRef.current.offsetWidth)
      * active)
  }, [active])

  // handle image aspect ratio
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth < 500) {
        setIsPhone(true)
      } else {
        setIsPhone(false)
      }
    }

    handleResize() // Change screen on first render
    window.addEventListener('resize', handleResize) // Change screen variable on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const redirectTo = () => {
    navigate('/signIn')
  }

  return (
    <>
      <div className="expanding" ref={previewContainerRef}>
        <div className={`${!isPhone ? 'expanding-list' : 'list'}`} ref={previewRef}>
          {
          colors?.map((item, index) => (
            <button
              key={item.id}
              alt="expanding-item"
              type="button"
              style={{ background: item }}
              className="exp-container"
              onClick={() => setSelectedItem(index)}
            >
              <div className="pen expandable-pen" />
            </button>
          ))
        }
        </div>
      </div>
      { itemIsSelected
        ? (
          <EditCarousel
            itemInfo={data}
            position={selectedIndex}
            imageType="imageSrcHero"
            onClick={() => setItemIsSelected(!itemIsSelected)}
          />
        )
        : ''}
    </>
  )
}
// style={{ background: `linear-gradient(${!isPhone ? '180deg' : '-90deg'}, transparent 0%, #101010 ${!isPhone ? '90%' : '85%'}), url(${item.image})` }}

export default ExpandingCarousel

ExpandingCarousel.propTypes = {
  data: PropTypes.any,
}
ExpandingCarousel.defaultProps = {
  data: undefined,
}
