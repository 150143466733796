import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './input.scss'

const Textbox = ({
  label, defaultValue, rows,
  textboxName, initialItemList, setChanges,
}) => {
  const [itemList, setItemList] = useState(initialItemList || [{ value: '' }])

  const handleItemChange = (e, index) => {
    const { name, value } = e.target
    const list = [...itemList]
    list[index][name] = value

    if (setChanges) { setChanges(list) } else { setItemList(list) }
  }

  return (
    <div className="textbox-container">
      <label htmlFor={textboxName}>{label}</label>
      <textarea name={textboxName} rows={rows} cols="50" onChange={(e) => handleItemChange(e, 0)} defaultValue={defaultValue} />
    </div>
  )
}

Textbox.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  rows: PropTypes.string,
  initialItemList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  textboxName: PropTypes.string.isRequired,
  setChanges: PropTypes.func,
}

Textbox.defaultProps = {
  label: undefined,
  defaultValue: '',
  rows: '5',
  initialItemList: undefined,
  setChanges: undefined,
}

export default Textbox
