import React from 'react'
import PropTypes from 'prop-types'
import './error.scss'

const ErrorLog = ({ errorMssg }) => (
  <div className="error-wrapper">
    <span>{errorMssg}</span>
  </div>
)
ErrorLog.propTypes = {
  errorMssg: PropTypes.string,
}
ErrorLog.defaultProps = {
  errorMssg: '',
}
export default ErrorLog
