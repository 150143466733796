/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Clock from '../Clock'
import leftArrow from '../../../../assets/images/left-arrow.png'
import rightArrow from '../../../../assets/images/right-arrow.png'
import Button from '../../button/Button'
import './hero.scss'
import EditCarousel from '../EditCarousel/EditCarousel'

const Hero = ({ carousel }) => {
  const item = carousel?.assets.length > 0 ? carousel.assets : {
    assets: [
      {
        id: 'test',
        color: 'pink',
      },
    ],
  }
  // eslint-disable-next-line no-console
  console.log('iteeeem', item)
  const heroRef = React.useRef()
  const [active, setActive] = useState(0)
  const [itemIsSelected, setItemIsSelected] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const previewContainerRef = React.useRef()
  let trackpadEnabled = true
  let positionX = 0

  useEffect(() => {
    heroRef.current.scrollLeft = heroRef.current.offsetWidth * active
  }, [active])

  const handleTrackpadStart = ({ target, deltaX }) => {
    if (!trackpadEnabled || Math.abs(deltaX) < 30 || target.classList[0] !== 'hero-item') return
    if (deltaX > 30) {
      trackpadEnabled = false
      setTimeout(() => {
        trackpadEnabled = true
      }, 500)
      goNext()
    } else if (deltaX < -30) {
      trackpadEnabled = false
      setTimeout(() => {
        trackpadEnabled = true
      }, 500)
      goBack()
    }
  }

  const handleTouchStart = ({ changedTouches, target }) => {
    if (target.classList[0] !== 'hero-item') return
    positionX = changedTouches[0]?.clientX
    window.addEventListener('touchend', handleTouchEnd)
  }

  const handleTouchEnd = ({ changedTouches }) => {
    const horizontalMovement = (changedTouches[0]?.clientX || 0) - positionX
    if (horizontalMovement > 50) {
      goBack()
    } else if (horizontalMovement < -50) {
      goNext()
    }
    window.removeEventListener('touchend', handleTouchEnd)
  }
  // handle image aspect ratio
  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('mousewheel', handleTrackpadStart)
    return () => {
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('mousewheel', handleTrackpadStart)
    }
  }, [])

  const goNext = () => {
    // Move to hero item to the right
    setActive((oldActive) => (oldActive + 1) % item.assets.length)
  }

  const goBack = () => {
    // Move to hero item to the left
    setActive((oldActive) => (oldActive !== 0 ? oldActive - 1 : item.assets.length - 1))
  }

  const goTo = (index) => {
    // Move to hero item
    setActive(index)
  }

  const setSelectedItem = (index) => {
    setItemIsSelected(!itemIsSelected)
    setSelectedIndex(index)
  }
  const handleLive = (heroItem) => {
    if (Date.parse(heroItem.start)) {
      if ((Date.parse(heroItem.start) < Date.parse(new Date())) && heroItem.end > (new Date()).toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
      })) {
        return true
      }
    }
    if (heroItem.start < (new Date()).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    }) && heroItem.end > (new Date()).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    })) {
      return true
    }
    return false
  }
  return (
    <>
      <div className="hero" ref={previewContainerRef}>
        <div className="hero-images">
          <Button onClick={goBack} classButton="left-arrow" icon={[leftArrow]} iconAlt="left-arrow" />
          <div className="hero-list" ref={heroRef}>
            {
          item.assets.map((asset, index) => (
            <button
              key={asset.id}
              alt="hero-item"
              className="hero-item"
              onClick={() => setSelectedItem(index)}
              type="button"
              style={{ left: `${index * 100}%`, background: `${asset.imageSrcHero ? `url(${asset.imageSrcHero['16x9']})` : {}}` }}
            >
              {
                asset.color
                  ? <div className="add" />
                  : (
                    <>
                      <div className="pen" />
                      <div className="hero-description-container">
                        <div className="hero-description-subitems">
                          {asset.highlight && !handleLive(asset)
                            ? <Clock item={asset} dt={asset.start} />
                            : ''}
                          <div className="live-container">
                            { handleLive(asset)
                              ? (
                                <span className="hero-live">
                                  Live in
                                </span>
                              )
                              : ''}
                            <img className="hero-container-image" src={asset.imageSrcLogo['1x1']} alt="hero channel" />
                          </div>
                        </div>
                        <h1 className="hero-title">
                          {asset.name}
                        </h1>
                      </div>
                    </>
                  )
              }
            </button>
          ))
        }
          </div>
          <Button onClick={goNext} classButton="right-arrow" icon={[rightArrow]} iconAlt="right-arrow" />
        </div>
        <div className="dot-list">
          {
            [...Array(item.assets.length)]?.map((x, index) => (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <button key={`dot-${index.toString()}`} onClick={() => goTo(index)} type="button" className={`${index === active ? 'dot-active' : 'dot'}`} />
            ))
        }
        </div>
      </div>
      { itemIsSelected
        ? (
          <EditCarousel
            itemInfo={item}
            position={selectedIndex}
            imageType="imageSrcHero"
            onClick={() => setItemIsSelected(!itemIsSelected)}
          />
        )
        : ''}
    </>
  )
}

Hero.propTypes = {
  carousel: PropTypes.shape({
    id: PropTypes.string,
    assets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        imageSrcHero: PropTypes.shape({
          type: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
}
export default Hero
