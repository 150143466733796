import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { forgotPasswordRequest } from '../../services/ForgotPassword/ForgotPasswordHandler'
import PasswordFlowHeader from '../Templates/PasswordFlowHeader'
import '../login/Login.scss'
import InputComponent from '../Templates/InputComponent'
import LoginFlowTitles from '../Templates/LoginFlowTitles'

const ResetPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [isOK, setIsOK] = useState(true)

  const handleForgotRequest = async () => {
    const [statusCode] = await forgotPasswordRequest(email)

    if (statusCode === 200) {
      navigate('/verification', {
        state: {
          mail: email,
        },
      })
    } else {
      setIsOK(false)
    }
  }

  const handleEmail = (value) => setEmail(value)
  return (
    <div className="screen-wrapper">
      <div className="form-wrapper">
        <PasswordFlowHeader route="" />
        <div className="form-body form-password">
          <LoginFlowTitles classSelected="reset-wrapper span-wrapper" title="Forgot Password?" spanClass="form-body-title" />
          <LoginFlowTitles classSelected="reset-wrapper span-wrapper" title="Email or username" />
          <InputComponent valueToHandle={email} setValueToHandle={handleEmail} isLoginOk={isOK} />
          {
            (isOK)
              ? ''
              : <LoginFlowTitles classSelected="span-wrapper" title="Invalid email or username" spanClass="forgot-control forgot-control-error" />
          }
          <input type="button" className="form-btn forgot-pass-btn" value="Send" onClick={handleForgotRequest} />
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
