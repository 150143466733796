/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch, useSelector } from 'react-redux'
import './editEPG.scss'
import Input from '../../input/Input'
import Textbox from '../../input/Textbox'
import Button from '../../button/Button'
import { EditProgramRequestModel } from '../../../services/models/edit-program.request.model.tsx'
import { editEPGProgram } from '../../../services/epg.service.tsx'
import ErrorLog from '../../Error/Error'
import { loggedUser, SET_EDITED_EPG } from '../../../UserContext/UserReducer'
import Spinner from '../../splashscreen/Spinner'
import { objectsEqual } from '../../../utils/EventUtils'

const EditProgram = ({
  setScheduleJson,
  scheduleJson,
  programInfo,
  categories,
  onClick,
}) => {
  const [editedProgram, setEditedProgram] = useState([cloneDeep(programInfo)])
  const [editedCategories, setEditedCategories] = useState(programInfo.categories)
  const [showError, setShowError] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)

  const userState = useSelector(loggedUser)
  const dispatch = useDispatch()

  /**
   * Update Schedule Json on context to prevent re-fretching epg
   */
  const updateProgramInfo = async () => {
    // Get channel index
    const channelIndex = await scheduleJson.findIndex(
      (channel) => channel.id === editedProgram[0].channel,
    )
    // Get program index
    const programIndex = await scheduleJson[channelIndex]
      .programs.findIndex((program) => program.id === editedProgram[0].id)

    // Replace program with new information
    scheduleJson[channelIndex].programs[programIndex] = {
      ...editedProgram[0],
    }

    setScheduleJson([...scheduleJson])
  }

  const editProgram = async () => {
    if (objectsEqual(editedProgram, [programInfo])) return

    /**
     * Request edit program, get changes and send request to endpoint
     */
    const request = new EditProgramRequestModel()
    request.id = editedProgram[0].id
    request.name = editedProgram[0].name
    request.description = editedProgram[0].description
    request.imageURL = editedProgram[0]?.imageSrcThumbnail['16x9']
    request.categories = editedProgram[0].categories

    await editEPGProgram(request, userState.authToken).then(async (res) => {
      // If the changes are successful
      if (res === 200) {
        updateProgramInfo()
        dispatch(SET_EDITED_EPG({
          editedEPG: true,
        }))
      } else setShowError(true)
    })
  }

  const saveChanges = async () => {
    setShowSpinner(true)
    await editProgram()
    setShowSpinner(false)
    onClick()
  }

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false)
      }, 5000)
    }
  }, [showError])

  useEffect(() => {
    setEditedProgram([{
      ...editedProgram[0],
      categories: editedCategories,
    }])
  }, [editedCategories])

  return (
    <div className="editEPG">
      <div className="container">
        <div className="header">
          <div className="block-header">
            <h4>Channel:</h4>
            <p>
              {programInfo.channel}
            </p>
          </div>
          <div className="block-header">
            <h4>Hour:</h4>
            <p>
              {programInfo.start.toLocaleTimeString()}
              {' - '}
              {programInfo.end.toLocaleTimeString()}
            </p>
          </div>
        </div>
        <div className="content">
          {showError ? <ErrorLog errorMssg="Oops! Something went wrong. Try again" /> : '' }
          {showSpinner ? <Spinner /> : '' }
          <div className="content-edit-container">
            <div className="editEPG-left-column">
              <Input
                showMiniature
                miniatureIAR="16x9"
                label="Image URL"
                initialItemList={editedProgram}
                inputName="imageSrcThumbnail"
                defaultValue={programInfo?.imageSrcThumbnail['16x9']}
                setChanges={setEditedProgram}
              />
              <Textbox
                label="Synopsis"
                defaultValue={programInfo.description}
                initialItemList={editedProgram}
                textboxName="description"
                setChanges={setEditedProgram}
              />
            </div>
            <div className="editEPG-right-column">
              <Input
                label="Program Name"
                initialItemList={editedProgram}
                inputName="name"
                setChanges={setEditedProgram}
              />
              <Input
                label="Categories"
                type="checkbox"
                inputName="categories"
                initialItemList={categories}
                validateChecked={editedCategories}
                setChanges={setEditedCategories}
              />
            </div>
          </div>
          <div className="content-button-container">
            <div className="right-buttons">
              <Button classButton="cancel" text="Cancel" onClick={() => onClick()} />
              <Button
                text="Save"
                classButton={`${objectsEqual(editedProgram, [programInfo]) ? '' : 'changes_detected'}`}
                onClick={saveChanges}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EditProgram.propTypes = {
  programInfo: PropTypes.shape({
    channel: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    description: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
    imageSrcThumbnail: PropTypes.shape({
      '16x9': PropTypes.string,
      type: PropTypes.string,
    }),
    inCarousel: PropTypes.bool,
  }).isRequired,
  scheduleJson: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      programs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
    }),
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
  setScheduleJson: PropTypes.func,
}

EditProgram.defaultProps = {
  categories: [],
  scheduleJson: {},
  onClick: () => {},
  setScheduleJson: () => {},
}

export default EditProgram
