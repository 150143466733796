import React from 'react'
import PropTypes from 'prop-types'
import './hours.scss'

const Hours = ({ minuteInPixels, hoursHeight }) => {
  const borderInPixels = 2
  // Schedule hours will be every 30 minutes
  const getScheduleHours = () => {
    const hours = []
    for (let i = 0; i < 24; i += 1) {
      // starting the schedule on 0:30
      hours.push(`${i}:30`)
      // if it is 24, then go back to 00:00, else continue adding one hour
      if (i + 1 === 24) hours.push('00:00'); else hours.push(`${i + 1}:00`)
    }
    return hours
  }

  return (
    <div className="hours">
      {getScheduleHours().map((hour) => (
        <h1
          key={hour}
          style={{
            width: (minuteInPixels * 30) - borderInPixels,
            height: hoursHeight,
          }}
        >
          {hour}

        </h1>
      ))}
    </div>
  )
}

Hours.propTypes = {
  minuteInPixels: PropTypes.number,
  hoursHeight: PropTypes.number,
}

Hours.defaultProps = {
  minuteInPixels: 0,
  hoursHeight: 0,
}

export default Hours
