/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch, useSelector } from 'react-redux'
import './editEPG.scss'
import Input from '../../input/Input'
import Textbox from '../../input/Textbox'
import Button from '../../button/Button'
import { EditChannelRequestModel } from '../../../services/models/edit-channel.request.model.tsx'
import { editEPGChannel } from '../../../services/epg.service.tsx'
import ErrorLog from '../../Error/Error'
import { loggedUser, SET_EDITED_EPG } from '../../../UserContext/UserReducer'
import Spinner from '../../splashscreen/Spinner'
import Checkbox from '../../input/Checkbox'
import { objectsEqual } from '../../../utils/EventUtils'
import { createAsset, removeAsset } from '../../../services/carousels/carousel.service.tsx'
import { CreateAssetRequestModel } from '../../../services/models/create-asset.request.model.tsx'

const EditChannel = ({
  channelInfo,
  onClick,
  scheduleJson,
  setScheduleJson,
}) => {
  const userState = useSelector(loggedUser)
  const [editedChannel, setEditedChannel] = useState([cloneDeep(channelInfo)])
  const [showSpinner, setShowSpinner] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showInCarousel, setShowInCarousel] = useState(channelInfo.inCarousel)
  const dispatch = useDispatch()

  /**
   * Update Schedule Json on context to prevent re-fretching epg
   */
  const updateChannelInfo = async () => {
    // Get channel index
    const channelIndex = await scheduleJson.findIndex(
      (channel) => channel.id === editedChannel[0].id,
    )
    // Replace program with new information
    scheduleJson[channelIndex] = { ...editedChannel[0], inCarousel: showInCarousel }
    setScheduleJson([...scheduleJson])
  }

  const editChannel = async () => {
    if (objectsEqual(editedChannel, [channelInfo])) return
    /**
     * Request edit program, get changes and send request to endpoint
     */
    const request = new EditChannelRequestModel()
    request.id = editedChannel[0].id
    request.name = editedChannel[0].name
    request.description = editedChannel[0].description
    request.imageURL = editedChannel[0].imageSrcLogo['1x1']

    setShowSpinner(true)
    await editEPGChannel(request, userState.authToken).then(async (res) => {
      // If the changes are successful
      if (res === 200) {
        updateChannelInfo()
        dispatch(SET_EDITED_EPG({
          editedEPG: true,
        }))
      } else setShowError(true)
      onClick()
    })
    setShowSpinner(false)
  }

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false)
      }, 5000)
    }
  }, [showError])

  const addToCarousel = async () => {
    if (showInCarousel === channelInfo.inCarousel) return

    /**
     * Request add program to carousel, get changes and send request to endpoint
     */
    const request = new CreateAssetRequestModel()
    request.carouselName = 'channels'
    request.carouselType = 'normal'
    request.assetType = 'channels'
    request.carouselRegionId = window.regionID

    if (showInCarousel) {
      await createAsset(
        { ...request, ...editedChannel[0] },
        userState.authToken,
      ).then(async (res) => {
        if (res === 200) {
          updateChannelInfo()
        } else { setShowError(true) }
      })
    } else {
      await removeAsset(
        { ...request, ...editedChannel[0] },
        userState.authToken,
      ).then(async (res) => {
        if (res === 200) {
          updateChannelInfo()
        } else { setShowError(true) }
      })
    }
  }

  const saveChanges = async () => {
    setShowSpinner(true)
    await Promise.all([editChannel(), addToCarousel()])
    setShowSpinner(false)
    onClick()
  }

  const handleCheckboxChange = (e) => {
    const { checked } = e.target
    setShowInCarousel(checked)
  }

  return (
    <div className="editEPG">
      <div className="container">
        <div className="header">
          <div className="block-header">
            <h4>Channel:</h4>
            <p>
              {channelInfo.name}
            </p>
          </div>
        </div>
        <div className="content">
          {showError ? <ErrorLog errorMssg="Oops! Something went wrong. Try again" /> : '' }
          {showSpinner ? <Spinner /> : '' }
          <div className="content-edit-container">
            <div className="editEPG-left-column">
              <Input
                showMiniature
                miniatureIAR="1x1"
                label="Image URL"
                initialItemList={editedChannel}
                inputName="imageSrcLogo"
                defaultValue={editedChannel[0].imageSrcLogo['1x1']}
                setChanges={setEditedChannel}
              />
            </div>
            <div className="editEPG-right-column">
              <Input
                label="Channel Name"
                initialItemList={editedChannel}
                inputName="name"
                setChanges={setEditedChannel}
              />
              <div className="input-full-container">
                <Checkbox
                  label="Display this channel on channel's carousel"
                  handleItemChange={handleCheckboxChange}
                  checked={showInCarousel}
                />
              </div>
              <Textbox
                label="Description"
                value={channelInfo.description}
                rows="7"
                defaultValue={channelInfo.description}
                initialItemList={editedChannel}
                textboxName="description"
                setChanges={setEditedChannel}
              />
            </div>
          </div>
          <div className="content-button-container">
            <div className="right-buttons">
              <Button classButton="cancel" text="Cancel" onClick={() => onClick()} />
              <Button
                text="Save"
                classButton={`${objectsEqual(editedChannel, [channelInfo])
            && showInCarousel === channelInfo.inCarousel ? '' : 'changes_detected'}`}
                onClick={saveChanges}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EditChannel.propTypes = {
  channelInfo: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    imageSrcLogo: PropTypes.shape({
      '1x1': PropTypes.string,
      type: PropTypes.string,
    }),
    inCarousel: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func,
  scheduleJson: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      programs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
    }),
  ),
  setScheduleJson: PropTypes.func,
}

EditChannel.defaultProps = {
  onClick: () => {},
  scheduleJson: {},
  setScheduleJson: () => {},
}

export default EditChannel
