/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */

import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  SET_USER_LOGGED,

  loggedUser,
} from '../../UserContext/UserReducer'
import PasswordFlowHeader from '../Templates/PasswordFlowHeader'
import NewPasswordChallenge from '../../services/LogIn/NewPasswordHandler'
import { confirmForgotPasswordRequest } from '../../services/ForgotPassword/ForgotPasswordHandler'
import './newpassword.scss'
import InputComponent from '../Templates/InputComponent'
import LoginFlowTitles from '../Templates/LoginFlowTitles'

const NewPassword = () => {
  const navigate = useNavigate()
  const userState = useSelector(loggedUser)
  const dispatch = useDispatch()
  const [firstPassword, setFirstPassword] = useState('')
  const [secondPassword, setSecondPassword] = useState('')
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [passwordMatchesRegex, setPasswordMatchesRegex] = useState(true)
  const passwordTemplate = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$/
  const [isOK, setIsOK] = useState(true)
  const [errorMssg, setErrorMssg] = useState('')
  const location = useLocation()

  const handleFirstPassword = (value) => setFirstPassword(value)
  const handleSecondPassword = (value) => setSecondPassword(value)

  const handleForgotRequest = async () => {
    if (firstPassword !== secondPassword) {
      setIsOK(false)
      setErrorMssg('Passwords does not match')
    } else {
      const { state } = location
      const mail = state?.mail
      const verificationCode = state?.code
      const [statusCode, response] = await confirmForgotPasswordRequest({
        username: mail,
        password: firstPassword,
        code: verificationCode.join(''),
      })

      if (statusCode === 200) {
        navigate('/', { state: {} })
      } else {
        setIsOK(false)
        setErrorMssg(response.message)
      }
    }
  }

  const newPasswordFirstTimeChallenge = async () => {
    const [statusCode, response] = await NewPasswordChallenge({
      challenge: 'NEW_PASSWORD_REQUIRED',
      user: userState.name,
      password: firstPassword.trim(),
      session: userState.sessionToken,
    })
    if (statusCode === 200 && response) {
      dispatch(SET_USER_LOGGED({ // WE ONLY DO THE UPDATE ONCE THE PASSWORD CHANGE IS DONE CORRECTLY
        name: userState.name,
        authToken: response.AuthenticationResult.AccessToken,
        refreshToken: response.AuthenticationResult.RefreshToken,
        isActive: true,
      }))
      navigate('/epg')
    }
  }

  const handleNewPassword = () => {
    setPasswordMatchesRegex(true) // PREVENTS WARNINGS FROM OVERLAPPING
    // eslint-disable-next-line max-len
    if (firstPassword.trim() !== secondPassword.trim()) { // THROWS ERROR IF PASSWORDS ARE NOT THE SAME
      setPasswordsMatch(false)
      return
    }
    if (userState.sessionToken) {
      setPasswordsMatch(true) // PREVENTS WARNINGS FROM OVERLAPPING
      if (!passwordTemplate.test(firstPassword.trim())) {
        setPasswordMatchesRegex(false)// THROWS ERROR IF PASSWORD FORMAT IS NOT APPROPIATE
        return
      }
      newPasswordFirstTimeChallenge()
    } else {
      handleForgotRequest()
    }
  }
  return (
    <div className="screen-wrapper">
      <div className="form-wrapper">
        <PasswordFlowHeader route="verification" />
        <div className="form-body form-password">
          <LoginFlowTitles classSelected="reset-wrapper span-wrapper" spanClass="form-body-title reset" title="Reset password" />
          <LoginFlowTitles classSelected="reset-wrapper span-wrapper" title="Password" />
          <InputComponent isLoginOk={(passwordsMatch && passwordMatchesRegex && isOK)} valueToHandle={firstPassword} setValueToHandle={handleFirstPassword} isPassword icon="/assets/images/Toggle.png" iconToggled="/assets/images/Untoggle.png" />
          <LoginFlowTitles classSelected="reset-wrapper span-wrapper" title="Confirm password" />
          <InputComponent isLoginOk={(passwordsMatch && passwordMatchesRegex && isOK)} valueToHandle={secondPassword} setValueToHandle={handleSecondPassword} isPassword icon="/assets/images/Toggle.png" iconToggled="/assets/images/Untoggle.png" />
          {
            !passwordsMatch
              ? <LoginFlowTitles classSelected="reset-wrapper span-wrapper" spanClass="forgot-control forgot-control-error" title="Passwords doesn't match" />
              : ''
          }
          {
            !passwordMatchesRegex
              ? <LoginFlowTitles classSelected="reset-wrapper span-wrapper" spanClass="reset-wrapper span-wrapper forgot-control forgot-control-error control-error-regex" title="Password requires at least 8 characters: 1 uppercase, 1 lowercase, 1 digit, 1 special character." />
              : ''
          }
          {
            (errorMssg === '')
              ? null
              : <LoginFlowTitles classSelected="reset-wrapper span-wrapper" spanClass="reset-wrapper span-wrapper forgot-control forgot-control-error" title={errorMssg} />
          }
          <input type="button" className="form-btn recover-pass-btn" value="Change password" onClick={() => handleNewPassword()} />
        </div>
      </div>
    </div>
  )
}

export default NewPassword
