/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import './program.scss'
import EditProgram from '../EditEPG/EditProgram'
import { objectsEqual } from '../../../utils/EventUtils'
import { getCurrentDateTime } from '../../../utils/dateUtils'

// Function to substract the dates
const substractDates = (endTime, startTime) => Math.abs(endTime - startTime)

const borderInPixels = 2
/**
 * @param {Program.programInfo} programInfo
 * @param {Program.actualProgram} actualProgram
 * @param {Number} minuteInPixels
 * @returns Array of [minuts, realPercentage]
 */
const getProgramTime = (programInfo) => {
  let minutes

  const todaysDate = new Date(getCurrentDateTime())

  try {
    const changedDate = document.getElementById('EPG-date').value.split('-')
    todaysDate.setDate(parseInt(changedDate[2], 10))
  // eslint-disable-next-line no-empty
  } catch (e) { }

  if (programInfo.start.getDate() === todaysDate.getDate() - 1) {
    const midnight = todaysDate
    midnight.setHours(0, 0, 0, 0)

    minutes = substractDates(programInfo.end, midnight) / 60000
  } else {
    minutes = substractDates(programInfo.end, programInfo.start) / 60000
  }

  return minutes
}

const Program = ({
  programInfo, channelHeight, minuteInPixels,
  categories, setScheduleJson, scheduleJson,
}) => {
  const [miliseconds, setMiliseconds] = useState(0)
  const [programIsSelected, setProgramIsSelected] = useState(false)
  const programRef = useRef(null)

  useEffect(() => {
    setMiliseconds(substractDates(programInfo.end, programInfo.start))
  }, [])

  // Function to get program width
  const getProgramLength = () => {
    const minutes = getProgramTime(programInfo)

    return {
      width: `${minutes * minuteInPixels - borderInPixels}px`,
      maxWidth: `${minutes * minuteInPixels - borderInPixels}px`,
      height: channelHeight - borderInPixels,
      maxHeight: channelHeight,
    }
  }

  return (
    programInfo.name ? (
      <>
        <div className="program" ref={programRef} style={getProgramLength(miliseconds)} onClick={() => setProgramIsSelected(!programIsSelected)}>
          <div className="pen" />
          <h1>{programInfo.name}</h1>
          <h2>{new Date(programInfo.start).toLocaleTimeString()}</h2>
        </div>
        { programIsSelected ? (
          <EditProgram
            showModal={programIsSelected}
            programInfo={programInfo}
            categories={categories}
            onClick={() => setProgramIsSelected(!programIsSelected)}
            setScheduleJson={setScheduleJson}
            scheduleJson={scheduleJson}
          />
        ) : '' }
      </>
    ) : (
      <div className="program" ref={programRef} style={getProgramLength(miliseconds)} />
    )
  )
}

Program.propTypes = {
  programInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    channel: PropTypes.string.isRequired,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  scheduleJson: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  minuteInPixels: PropTypes.number,
  channelHeight: PropTypes.number,
  setScheduleJson: PropTypes.func,
}

Program.defaultProps = {
  programInfo: {
    name: '',
    id: '',
    start: new Date(),
    end: new Date(),
    channel: '',
  },
  scheduleJson: {},
  categories: [],
  minuteInPixels: 0,
  channelHeight: 0,
  setScheduleJson: () => {},
}

/**
 * Checks if the program needs a render
 * @param {Object} oldState previous props of component
 * @param {Object} newState new props of component
 * @returns (
 *  true -> doesn't renders component
 *  false -> renders the component
 * )
 */
const checkCanRender = (oldState, newState) => {
  // Render if heights are different
  if (oldState.channelHeight !== newState.channelHeight) {
    return false
  }
  // Render if programInfo are different
  if (!objectsEqual(oldState.programInfo, newState.programInfo)) {
    return false
  }
  return true
}

const MemoProgram = React.memo(Program, checkCanRender)

export default MemoProgram
