import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import './button.scss'

const Button = ({
  iconAlt, icon, text, buttonType, onClick, classButton, iconClass, title,
}) => {
  const [className, setClassName] = useState('')
  const iconType = className.includes('selected') ? icon[1] : icon[0]
  const location = useLocation()
  const navigate = useNavigate()

  const buttonBehaviour = () => {
    if (buttonType === 'logo') {
      navigate('/')
    } else if (onClick) {
      onClick()
    } else {
      navigate(`/${text.toLowerCase().replace(/\s/g, '')}`)
    }
  }

  useEffect(() => {
    if (location.pathname.toLowerCase().includes(text?.toLowerCase()) && buttonType === 'sidebar') {
      setClassName('active')
    } else if (location.pathname.toLowerCase().includes(text?.toLowerCase()) && buttonType === 'tabRedirect') {
      setClassName('responsive-active')
    } else {
      setClassName('')
    }
  }, [location.pathname])

  return (
    <button title={title} type="button" className={`${classButton} ${className}`} onClick={() => buttonBehaviour()}>
      { icon.length > 0 ? <img className={iconClass} alt={iconAlt} src={iconType} /> : '' }
      { text ? <span>{text}</span> : ''}
    </button>
  )
}

Button.propTypes = {
  iconAlt: PropTypes.string,
  icon: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string,
  buttonType: PropTypes.string,
  onClick: PropTypes.func,
  classButton: PropTypes.string,
  iconClass: PropTypes.string,
  title: PropTypes.string,
}

Button.defaultProps = {
  iconAlt: undefined,
  icon: [],
  text: undefined,
  buttonType: undefined,
  onClick: undefined,
  classButton: undefined,
  iconClass: '',
  title: undefined,
}

export default Button
