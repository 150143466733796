const logInUser = async (credentials) => {
  try {
    // WE MAY RETURN ENTIRE RESPONSE O JUST THE STATUS CODE 200 (OK), BUT WE NEED AUTH TOKEN
    return await fetch(`${process.env.API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: credentials.user,
        password: credentials.password,
      }),
    }).then(async (res) => [res.status, await res.json()])
  } catch (error) {
    return ('Something went wrong. Error: ', error)
  }
}

export default logInUser
