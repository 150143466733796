/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import plus from '../../../assets/images/plus.png'
import minus from '../../../assets/images/minus.png'
import './input.scss'
import Button from '../button/Button'
import Checkbox from './Checkbox'

const Input = ({
  label, placeholder, defaultValue,
  showMiniature, miniatureIAR,
  showAdd, showRemove, type, inputName,
  initialItemList, validateChecked,
  setChanges, position,
}) => {
  const [itemList, setItemList] = useState(initialItemList || [{ value: '' }])

  const handleItemChange = (e, index) => {
    const { name, value } = e.target
    const list = [...itemList]

    if (inputName.includes('image')) {
      list[index][name][miniatureIAR] = value
    } else {
      list[index][name] = value
    }

    if (setChanges) { setChanges(list) } else { setItemList(list) }
  }

  const handleItemRemove = (index) => {
    const list = [...itemList]
    list.splice(index, 1)
    setChanges(list)
    setItemList(list)
  }

  const handleServiceAdd = () => {
    setItemList([...itemList, { [inputName]: '' }])
  }

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target

    const list = [...validateChecked]

    if (type === 'radio') {
      setChanges(parseInt(value, 10))
    } else if (checked) {
      list.push(value)
      setChanges(list)
    } else {
      const index = list.indexOf(value)
      if (index !== -1) {
        list.splice(index, 1)
      }
      setChanges(list)
    }
  }

  return (
    <div className="input-full-container">
      { showMiniature ? (
        <img
          src={itemList[0][inputName][miniatureIAR]}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = ''
          }}
          className={`iar_${miniatureIAR}`}
          alt="Thumbnail"
        />
      ) : '' }

      {
        type === 'checkbox'
          ? (
            <div className="label-container">
              <label htmlFor={label}>{label}</label>
              <div className="items-checkbox">
                {
                  itemList.map((item) => (
                    <Checkbox
                      key={item._id}
                      label={item.value}
                      value={item._id}
                      checked={validateChecked.includes(item._id)}
                      handleItemChange={handleCheckboxChange}
                    />
                  ))
                }
              </div>
            </div>
          )
          : ''
      }

      {
        type === 'string'
          ? (
            <div className="label-container">
              {
              label
                ? (
                  <label htmlFor={label}>
                    {label}
                  </label>
                )
                : ''
            }

              {itemList.map((singleItem, index) => (
                <div
                  className="input-container"
                  style={itemList.length - 1 === index && showAdd ? { } : { flexDirection: 'row' }}
                  key={`${singleItem}-${index}`}
                >
                  <input
                    type={type}
                    name={inputName}
                    id={`${label}-${index}`}
                    placeholder={placeholder}
                    style={itemList.length > 1 && showRemove ? { width: '80%' } : { width: '100%' }}
                    onChange={(e) => handleItemChange(e, index)}
                    value={defaultValue || (
                      inputName.includes('image')
                        ? singleItem[inputName][miniatureIAR] : singleItem[inputName]
                    )}
                  />

                  {(itemList.length > 1 && showRemove)
                  && (
                  <Button onClick={() => handleItemRemove(index)} icon={[minus]} iconAlt="remove" />
                  ) }
                </div>
              ))}
            </div>
          )
          : ''
      }

      {
        type === 'radio'
          ? (
            <div className="label-container">
              {
                label
                  ? (
                    <label htmlFor={label}>
                      {label}
                    </label>
                  )
                  : ''
              }

              <div
                className="radio-container"
              >
                {itemList.map((singleItem, index) => (
                  <div
                    className="radio-item"
                    key={`${singleItem}-${index}`}
                  >
                    <input
                      type={type}
                      name={inputName}
                      id={`${label}-${index}`}
                      value={index}
                      onChange={handleCheckboxChange}
                      defaultChecked={index === position}
                    />
                    <label htmlFor={singleItem}>
                      {index + 1}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ) : ''
      }

      { showAdd && (
        <Button onClick={handleServiceAdd} classButton="add-more" icon={[plus]} iconAlt="add-more" text="Add a Category" />
      )}
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showMiniature: PropTypes.bool,
  miniatureIAR: PropTypes.string,
  showAdd: PropTypes.bool,
  showRemove: PropTypes.bool,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  validateChecked: PropTypes.arrayOf(PropTypes.string),
  initialItemList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  inputName: PropTypes.string.isRequired,
  setChanges: PropTypes.func,
  position: PropTypes.number,
}

Input.defaultProps = {
  label: '',
  placeholder: '',
  miniatureIAR: '3x2',
  showMiniature: false,
  showAdd: false,
  showRemove: false,
  defaultValue: undefined,
  type: 'string',
  validateChecked: [],
  initialItemList: undefined,
  setChanges: undefined,
  position: 0,
}

export default Input
