/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch, useSelector } from 'react-redux'
import '../../schedule/EditEPG/editEPG.scss'
import './editCarousel.scss'
import Input from '../../input/Input'
import Button from '../../button/Button'
import ErrorLog from '../../Error/Error'
import { loggedUser, SET_EDITED_CAROUSELS } from '../../../UserContext/UserReducer'
import Spinner from '../../splashscreen/Spinner'
import remove from '../../../../assets/images/remove.png'
import Checkbox from '../../input/Checkbox'
import { EditCarouselRequestModel } from '../../../services/models/edit-carousel.request.model.tsx'
import { updateAsset, removeAsset } from '../../../services/carousels/carousel.service.tsx'
import { CreateAssetRequestModel } from '../../../services/models/create-asset.request.model.tsx'
import { objectsEqual } from '../../../utils/EventUtils'

const EditCarousel = ({
  itemInfo,
  onClick,
  position,
  imageType,
  iar,
}) => {
  const [editedItem, setEditedItem] = useState([cloneDeep(itemInfo.assets[position])])
  const [editedPosition, setEditedPosition] = useState(position)
  const [showError, setShowError] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)

  const userState = useSelector(loggedUser)
  const dispatch = useDispatch()

  const saveChanges = async () => {
    /**
     * Request edit carousel item, get changes and send request to endpoint
     */
    const request = new EditCarouselRequestModel()
    request.id = itemInfo.id

    // Swap items positions if needed and send new item info
    itemInfo.assets[position] = itemInfo.assets[editedPosition]
    itemInfo.assets[editedPosition] = editedItem[0]
    request.assets = itemInfo.assets

    setShowSpinner(true)
    await updateAsset(request, userState.authToken).then(async (res) => {
      // If the changes are successful
      if (res !== 200) setShowError(true)
      else {
        dispatch(SET_EDITED_CAROUSELS({
          editedCarousels: true,
        }))
      }
      onClick()
    })
    setShowSpinner(false)
  }

  const removeFromCarousel = async () => {
    /**
     * Request remove item from carousel, get changes and send request to endpoint
     */
    const request = new CreateAssetRequestModel()
    request.carouselID = itemInfo.id
    request.carouselRegionId = itemInfo.region

    await removeAsset(
      { ...request, ...editedItem[0] },
      userState.authToken,
    ).then(async (res) => {
      if (res !== 200) setShowError(true)
      else {
        // Remove item from array (locally)
        itemInfo.assets.splice(editedPosition, 1)
        dispatch(SET_EDITED_CAROUSELS({
          editedCarousels: true,
        }))
      }
      onClick()
    })
  }

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false)
      }, 5000)
    }
  }, [showError])

  const handleCheckboxChange = (e) => {
    const { checked } = e.target
    setEditedItem([{ ...editedItem[0], highlight: checked }])
  }

  return (
    <div className="editEPG">
      <div className="container">
        <div className="header">
          {
            editedItem[0].assetType === 'program'
              ? (
                <>
                  <div className="block-header">
                    <h4>Channel:</h4>
                    <p>
                      {editedItem[0].channel}
                    </p>
                  </div>
                  <div className="block-header">
                    <h4>Program Name:</h4>
                    <p>
                      {editedItem[0].name}
                    </p>
                  </div>

                </>
              )
              : editedItem[0].assetType === 'program' ? (
                <div className="block-header">
                  <h4>Channel:</h4>
                  <p>
                    {editedItem[0].name}
                  </p>
                </div>
              ) : (
                <div className="block-header">
                  <h4>Expandable Carousel:</h4>
                  <p>
                    {editedItem[0].name}
                  </p>
                </div>
              )
          }
        </div>
        <div className="content">
          {showError ? <ErrorLog errorMssg="Oops! Something went wrong. Try again" /> : '' }
          {showSpinner ? <Spinner /> : '' }
          <div className="edit-carousel-content">
            <div className="edit-carousel-top-row">
              <Input
                showMiniature
                miniatureIAR={iar}
                label="Image URL"
                initialItemList={editedItem}
                inputName={imageType}
                defaultValue={editedItem[0][imageType][iar]}
                setChanges={setEditedItem}
              />
            </div>
            <div className="edit-carousel-bottom-row">
              <Input
                label="Position to be displayed at"
                type="radio"
                inputName="assets"
                initialItemList={[...Array(itemInfo.assets.length)]}
                setChanges={setEditedPosition}
                position={position}
              />
              {
                editedItem[0].assetType !== 'expandable'
                  ? (
                    <Checkbox
                      label="Is important event"
                      handleItemChange={handleCheckboxChange}
                      checked={editedItem[0].highlight}
                    />
                  ) : ''
              }
            </div>
          </div>
          <div className="content-button-container">
            <Button text="Remove from carousel" classButton="remove" iconAlt="remove" icon={[remove]} onClick={removeFromCarousel} />
            <div className="right-buttons">
              <Button text="Cancel" classButton="cancel" onClick={() => onClick()} />
              <Button
                text="Save"
                classButton={`${objectsEqual(editedItem, [itemInfo.assets[position]])
                  && position === editedPosition ? '' : 'changes_detected'}`}
                onClick={saveChanges}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EditCarousel.propTypes = {
  itemInfo: PropTypes.shape({
    id: PropTypes.string,
    region: PropTypes.string,
    assetType: PropTypes.string,
    assets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        imageSrcHero: PropTypes.shape({
          type: PropTypes.string,
          '16x9': PropTypes.string,
        }),
        channel: PropTypes.string,
        name: PropTypes.string,
        start: PropTypes.string,
        end: PropTypes.string,
      }),
    ),
  }).isRequired,
  onClick: PropTypes.func,
  position: PropTypes.number,
  imageType: PropTypes.string.isRequired,
  iar: PropTypes.string,
}

EditCarousel.defaultProps = {
  onClick: () => {},
  position: 0,
  iar: '16x9',
}

export default EditCarousel
