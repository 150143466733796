import React from 'react'
import PropTypes from 'prop-types'
import './selector.scss'

const Selector = ({
  handleChange, items, icon, iconAlt,
}) => (
  <div className="region-dropdown-wrapper drop">
    { icon ? <img src={icon} alt={iconAlt} /> : '' }
    <select id="regions" name="regions" className="select-regions" onChange={handleChange}>
      {
      items.map((item, index) => <option value={index} key={item.id}>{item.value}</option>)
    }
    </select>
  </div>
)

Selector.propTypes = {
  handleChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
}

Selector.defaultProps = {
  icon: undefined,
  iconAlt: '',
}

export default Selector
