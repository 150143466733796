/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import Program from './Program'
import Hours from '../Hours/Hours'
import { returnScheduleContext } from '../ScheduleContext'

const Programs = ({
  // eslint-disable-next-line no-unused-vars
  channelsRef, selectedDate, navbarRef,
}) => {
  const completeSchedule = React.createRef()

  const {
    scheduleJson, channelHeight, minuteInPixels,
    hoursHeight, quantToShow, categories, setScheduleJson,
  } = returnScheduleContext()
  const minutesInDay = 1440

  /**
   * Move channels with the actual program scrolled on user view
   * @param {} value get scroll movement event
   */
  const handleScroll = (value) => {
    channelsRef.scroll({
      top: value.target.scrollTop,
    })
  }

  return (
    <div className="right-column">
      <div
        className="programs"
        ref={completeSchedule}
        // eslint-disable-next-line no-bitwise
        style={{ maxWidth: `calc(100vw - 20px - ${channelHeight}px - ${navbarRef.current && navbarRef.current.clientHeight > 70 ? navbarRef.current.clientWidth : 0}px)` }}
      >
        <Hours minuteInPixels={minuteInPixels} hoursHeight={hoursHeight} />
        <div className="programs-list" style={{ maxHeight: (channelHeight * quantToShow) }} onScroll={handleScroll}>
          { scheduleJson?.map((channel) => (
            <div className="programs-container" style={{ width: (minutesInDay * minuteInPixels), maxWidth: (minutesInDay * minuteInPixels) }} key={channel.id + channel.name}>
              <div className="programs-horizontal">
                { channel.programs && channel.programs.map((element, index) => (
                  <Program
                    // eslint-disable-next-line react/no-array-index-key
                    key={channel.id + element.start + index}
                    channelHeight={channelHeight}
                    programInfo={{
                      ...element, channel: channel.id, imageSrcLogo: channel.imageSrcLogo, assetType: 'program',
                    }}
                    minuteInPixels={minuteInPixels}
                    categories={categories}
                    setScheduleJson={setScheduleJson}
                    scheduleJson={scheduleJson}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

Programs.propTypes = {
  channelsRef: PropTypes.instanceOf(Element),
  selectedDate: PropTypes.string.isRequired,
  navbarRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
}

Programs.defaultProps = {
  channelsRef: document.getElementById('channels-list'),
  navbarRef: undefined,
}

export default Programs
