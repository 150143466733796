export const forgotPasswordRequest = async (mail) => {
  try {
    return await fetch(`${process.env.API_URL}/forgot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: mail,
      }),
    }).then(async (res) => [res.status, await res.json()])
  } catch (error) {
    return ('Something went wrong. Error: ', error)
  }
}

export const confirmForgotPasswordRequest = async (credentials) => {
  try {
    return await fetch(`${process.env.API_URL}/forgot/confirm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: credentials.username,
        password: credentials.password,
        code: credentials.code,
      }),
    }).then(async (res) => [res.status, await res.json()])
  } catch (error) {
    return ('Something went wrong. Error: ', error)
  }
}
