/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './channel.scss'
import EditChannel from '../EditEPG/EditChannel'
import { objectsEqual } from '../../../utils/EventUtils'

const Channel = ({
  channelInfo,
  channelHeight,
  paddingSize,
  scheduleJson,
  setScheduleJson,
}) => {
  const [channelIsSelected, setChannelIsSelected] = useState(false)

  return (
    <>
      <div
        className="channel-container"
        style={{
          height: (channelHeight - paddingSize * 2) - 2,
          width: (channelHeight - paddingSize * 2) - 2,
          padding: paddingSize,
        }}
        onClick={() => setChannelIsSelected(!channelIsSelected)}
      >
        <div className="pen" />
        <img
          className="channel"
          src={`${channelInfo.imageSrcLogo['1x1']}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = `https://ui-avatars.com/api/?name=${channelInfo.name}`
          }}
          alt="channel-icon"
        />
      </div>
      {
        channelIsSelected
          ? (
            <EditChannel
              channelInfo={channelInfo}
              onClick={() => setChannelIsSelected(!channelIsSelected)}
              scheduleJson={scheduleJson}
              setScheduleJson={setScheduleJson}
            />
          ) : ''
      }
    </>
  )
}

Channel.propTypes = {
  channelInfo: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    imageSrcLogo: PropTypes.shape({
      '1x1': PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
  channelHeight: PropTypes.number,
  paddingSize: PropTypes.number,
  scheduleJson: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      programs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
    }),
  ),
  setScheduleJson: PropTypes.func,
}

Channel.defaultProps = {
  channelHeight: 0,
  paddingSize: 0,
  scheduleJson: {},
  setScheduleJson: () => {},
}

/**
 * Checks if the program needs a render
 * @param {Object} oldState previous props of component
 * @param {Object} newState new props of component
 * @returns (
 *  true -> doesn't renders component
 *  false -> renders the component
 * )
 */
const checkCanRender = (oldState, newState) => {
  // Render if heights are different
  if (oldState.channelHeight !== newState.channelHeight) {
    return false
  }
  // Render if channel info is updated
  if (!objectsEqual(oldState.channelInfo, newState.channelInfo)) {
    return false
  }
  return true
}

const MemoChannel = React.memo(Channel, checkCanRender)

export default MemoChannel
