/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/prefer-default-export */
import React, {
  createContext, useContext, useEffect, useState,
} from 'react'
import { useSelector } from 'react-redux'
import { fetchEPG, fetchEPGCategories } from '../../services/epg.service.tsx'
import { loggedUser } from '../../UserContext/UserReducer'

const ScheduleContext = createContext({
  scheduleInfo: {
    id: '',
    name: '',
    schedules: [],
    logo: 'https://www.shareicon.net/data/512x512/2015/09/30/109354_media_512x512.png',
  },
})

// eslint-disable-next-line react/prop-types
export const FetchScheduleInfo = ({ children }) => {
  const userState = useSelector(loggedUser)
  const [scheduleJson, setScheduleJson] = useState()
  const [categories, setCategories] = useState()

  // CSS variables
  const [scrollWidth, setScrollWidth] = useState(12)
  const [channelHeight, setChannelHeight] = useState(90)
  const channelHeightInBiggerScreen = 60
  const [hoursHeight, setHoursHeight] = useState(20)
  const [minuteInPixels, setMinuteInPixels] = useState(5)
  const [paddingSize, setPaddingSize] = useState(2)
  const [quantToShow, setQuantToShow] = useState(0)

  /**
   * Function to get screen empty space
   * EPG will occupy all screen blank space
   */
  const getEmptySpaceHeight = () => {
    let emptyPixels = (
      window.visualViewport.width >= 1080
        ? window.visualViewport.height
        : window.screen.height)
        - (scrollWidth + document.getElementsByClassName('screen-header')[0].clientHeight
        + document.getElementsByClassName('header-wrapper')[0].clientHeight + hoursHeight)

    if (window.screen.availWidth < 500) {
      emptyPixels -= document.getElementsByClassName('nav-wrapper')[0].clientHeight
    }
    // Empty pixels will be divided by channels height and result will be rounded to one decimal
    setQuantToShow(((emptyPixels / channelHeight) * 10) / 10)
  }

  // handle image aspect ratio
  useEffect(() => {
    const handleResize = () => {
      if (window.visualViewport.width >= 1080) {
        setMinuteInPixels((window.visualViewport.width * minuteInPixels) / 1080)
        setChannelHeight((window.visualViewport.width * channelHeightInBiggerScreen) / 1080)
        setHoursHeight((window.visualViewport.width * hoursHeight) / 1080)
        setPaddingSize((window.visualViewport.width * paddingSize) / 1080)
      } else {
        setMinuteInPixels(5)
        setChannelHeight(90)
        setHoursHeight(20)
        setPaddingSize(2)
      }
    }

    handleResize() // Chech resize in first render
    window.addEventListener('resize', handleResize) // Change resize on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getScrollbarWidth = () => {
    let div = getScrollbarWidth.width
    let { width } = getScrollbarWidth
    if (width === undefined) {
      div = document.createElement('div')
      div.innerHTML = '<div style="width:50px;height:50px;position:absolute;left:-50px;top:-50px;overflow:auto;"><div style="width:1px;height:100px;"></div></div>'
      div = div.firstChild
      document.body.appendChild(div)
      // eslint-disable-next-line no-multi-assign
      width = getScrollbarWidth.width = div.offsetWidth - div.clientWidth
      document.body.removeChild(div)
    }
    return width
  }

  /**
   * Get EPG by selected date
   * @param {*} date Date to fecth from EPG
   */
  const fetchEPGDate = async (date, regionID, offset) => {
    // Fetching full schedule once
    const [statusCode, response] = await fetchEPG(date, userState.authToken, regionID, offset)
    setScheduleJson(response)
    return statusCode
  }

  useEffect(() => {
    setScrollWidth(getScrollbarWidth())
    fetchEPGCategories(userState.authToken).then((response) => {
      setCategories(response[1])
    })
  }, [])

  return (
    <ScheduleContext.Provider value={{
      fetchEPGDate,
      setScheduleJson,
      scheduleJson,
      categories,
      channelHeight,
      minuteInPixels,
      hoursHeight,
      paddingSize,
      quantToShow,
      getEmptySpaceHeight,
    }}
    >
      { children }
    </ScheduleContext.Provider>
  )
}

export const returnScheduleContext = () => {
  const context = useContext(ScheduleContext)
  if (!context) return 'There is not context'
  return context
}
