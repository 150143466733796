/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './Navbar.scss'
import Button from '../button/Button'
import tabsJson from '../../../assets/json/tabs.json'

const Navbar = ({ navbarRef, isSmallScreen }) => {
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    setTabs(tabsJson)
  }, [])
  return (
    <div className="nav-wrapper" ref={navbarRef}>
      <div className="nav-tabs-container">
        {
          tabs.map((section) => (
            section.tabs.length > 0 ? (
              section.tabs?.map((tabSec) => (
                <Button
                  classButton="nav-tab"
                  key={tabSec.tabName}
                  iconAlt={tabSec.tabName}
                  icon={[tabSec.icon]}
                  text={tabSec.tabName}
                  buttonType="sidebar"
                />
              ))
            ) : ''
          ))
        }
      </div>
    </div>
  )
}
Navbar.propTypes = {
  navbarRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  isSmallScreen: PropTypes.bool,
}

Navbar.defaultProps = {
  navbarRef: undefined,
  isSmallScreen: false,
}

export default Navbar
