/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './headers.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SET_USER_LOGGED_OUT, loggedUser } from '../../UserContext/UserReducer'
import logo from '../../../assets/images/whiteLogo.png'
import logout from '../../../assets/images/Logout.svg'
import Button from '../button/Button'
import openIcon from '../../../assets/images/open-menu.png'
import HamburguerMenu from '../Dropdown/HamburguerMenu'
import closeIcon from '../../../assets/images/close-menu.png'

const Header = ({ isSmallScreen }) => {
  const [isResponsiveSideNavOpen, setIsResponsiveSideNavOpen] = useState(false)
  const userState = useSelector(loggedUser)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleLogout = () => dispatch(SET_USER_LOGGED_OUT(userState))

  useEffect(() => {
    if (!userState.authToken && !userState.isActive) navigate('/')
  }, [userState])

  return (
    <div className="header-wrapper">
      <div className="header-left-side">
        <div className="open-icon-container">
          <Button
            classButton="open-icon"
            onClick={() => setIsResponsiveSideNavOpen((current) => !current)}
            iconAlt="Open"
            icon={[openIcon]}
          />
        </div>
        <Button icon={[logo]} iconAlt="Logo" buttonType="logo" classButton="logo-button" />
      </div>
      <div className="header-right-side">
        <Button icon={[logout]} iconAlt="Logout" onClick={() => handleLogout()} classButton="logout" />
      </div>
      {
        isResponsiveSideNavOpen
        ? (
          <div className="navbar-menu-mobile-container">
            <div className="open-icon-container close-icon-container">
              <Button
                icon={[closeIcon]}
                iconAlt="Close"
                onClick={() => setIsResponsiveSideNavOpen(false)}
                classButton="open-icon close-icon"
              />
            </div>
            <HamburguerMenu handleClose={setIsResponsiveSideNavOpen} />
          </div>
        )
      : ''
      }
    </div>
  )
}
Header.propTypes = {
  isSmallScreen: PropTypes.bool,
}

Header.defaultProps = {
  isSmallScreen: false,
}
export default Header
