import React from 'react'
/* eslint-disable import/prefer-default-export */

/**
   * Function to check if both objects are the same
   * Since initially both have the same values, the order is not an important factor
   * @param {*} o1 Object 1
   * @param {*} o2 Object 2
   * @returns boolean if objects are the same
   */
export const objectsEqual = (o1, o2) => JSON.stringify(o1) === JSON.stringify(o2)

/**
 * Get the header of the screens
 * @param selectedRegion STRING with region information
 * @returns header
 */
export const displayFilteredData = (selectedRegion) => {
  const timezoneOffsetParsed = parseFloat(selectedRegion.timezoneOffset) / 60
  return (
    <div className="dropdown-display">
      <span>
        Region:
        {' '}
        {selectedRegion.value}
      </span>
      <span>
        Time Zone:
        UTC-
        {timezoneOffsetParsed < 10 ? '0' : ''}
        {timezoneOffsetParsed}
      </span>
    </div>
  )
}
