import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router,
} from 'react-router-dom'

import './theme.scss'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './UserContext/store'
import App from './App'

const root = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>
)

ReactDOM.render(root, document.getElementById('root'))
