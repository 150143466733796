import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import './verification.scss'
import LoginFlowTitles from '../Templates/LoginFlowTitles'

const InputCodeBox = ({ handleCodeChange, code }) => {
  // const navigate = useNavigate()
  const inputs = useRef([])

  const processInput = (e, slot) => {
    const num = e.target.value
    const newCode = [...code]
    newCode[slot] = num
    handleCodeChange(newCode)
    if (slot !== 5) {
      inputs.current[slot + 1].focus()
    }
  }

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code]
      newCode[slot - 1] = ''
      handleCodeChange(newCode)
      inputs.current[slot - 1].focus()
    }
  }
  return (
    <div className="code-input">
      <LoginFlowTitles title="Enter your verification code sent by mail" classSelected="reset-wrapper span-wrapper" spanClass="code-label" />
      <div className="code-inputs">
        {code.map((num, idx) => (
          <input
            type="text"
            inputMode="numeric"
            maxLength={1}
            value={num}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={!code[0].length && idx === 0}
            onChange={(e) => processInput(e, idx)}
            onKeyUp={(e) => onKeyUp(e, idx)}
            ref={(ref) => inputs.current.push(ref)}
          />
        ))}
      </div>
    </div>
  )
}

InputCodeBox.propTypes = {
  handleCodeChange: PropTypes.func,
  code: PropTypes.arrayOf(PropTypes.string),
}

InputCodeBox.defaultProps = {
  handleCodeChange: undefined,
  code: [],
}

export default InputCodeBox
