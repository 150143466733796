import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'
import { loggedUser } from '../../UserContext/UserReducer'
import Button from '../button/Button'
import { fetchEPGCategories, editEPGCategories } from '../../services/epg.service.tsx'
import Input from '../input/Input'
import './categoriesScreen.scss'
import { EditChannelRequestModel } from '../../services/models/edit-categories.request.model.tsx'
import { objectsEqual } from '../../utils/EventUtils'

const CategoriesScreen = ({ handleSpinner, handleError }) => {
  const userState = useSelector(loggedUser)
  const navigate = useNavigate()
  const [categories, setCategories] = useState()
  const [editedCategories, setEditedCategories] = useState()

  const getCategories = () => {
    // Changing _id to id
    handleSpinner(true)
    fetchEPGCategories(userState.authToken).then((res) => {
      const [statusCode, response] = res

      if (statusCode === 200) {
        // Saving categories copy
        setCategories(response)
        setEditedCategories(cloneDeep(response))
        handleError(false)
      } else {
        handleError(true)
      }
    })
    handleSpinner(false)
  }

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    handleSpinner(!categories)
  }, [categories])

  /**
  * Request edit categories and send request to endpoint
  */
  const publishChanges = async () => {
    const request = new EditChannelRequestModel()
    request.oldCategories = categories
    request.newCategories = editedCategories

    handleSpinner(true)
    await editEPGCategories(request, userState.authToken).then((res) => {
      if (res === 200) {
        /**
         * Fetch categories again to trigger button color change
         */
        getCategories()
        handleError(false)
      } else handleError(true)
      handleSpinner(false)
    })
  }

  useEffect(() => {
    if (!userState.authToken && !userState.isActive) navigate('/')
  }, [userState])

  return (editedCategories
    ? (
      <div className="categories-screen">
        <div className="categories-header">
          <h2>Programs Categories</h2>
          <Button
            classButton={`publish ${objectsEqual(editedCategories, categories) ? '' : 'changes_detected'}`}
            onClick={objectsEqual(editedCategories, categories) ? () => {} : publishChanges}
            text="Publish"
          />
        </div>
        <Input
          initialItemList={editedCategories}
          setChanges={setEditedCategories}
          inputName="value"
          showAdd
          showRemove
        />
      </div>
    ) : ''
  )
}

CategoriesScreen.propTypes = {
  handleSpinner: PropTypes.func,
  handleError: PropTypes.func,
}

CategoriesScreen.defaultProps = {
  handleSpinner: undefined,
  handleError: undefined,
}

export default CategoriesScreen
